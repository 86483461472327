import CONFIGS from '@/configs'

export const WORKING_HOURS = [
  {
    '@type': 'OpeningHoursSpecification',
    dayOfWeek: 'Monday',
    opens: '09:00',
    closes: '20:00'
  },
  {
    '@type': 'OpeningHoursSpecification',
    dayOfWeek: 'Tuesday',
    opens: '09:00',
    closes: '20:00'
  },
  {
    '@type': 'OpeningHoursSpecification',
    dayOfWeek: 'Wednesday',
    opens: '09:00',
    closes: '20:00'
  },
  {
    '@type': 'OpeningHoursSpecification',
    dayOfWeek: 'Thursday',
    opens: '09:00',
    closes: '20:00'
  },
  {
    '@type': 'OpeningHoursSpecification',
    dayOfWeek: 'Friday',
    opens: '09:00',
    closes: '20:00'
  },
  {
    '@type': 'OpeningHoursSpecification',
    dayOfWeek: 'Saturday',
    opens: '10:00',
    closes: '18:00'
  }
]

export const SOCIAL_LINKS = []

export const BASE_URL = CONFIGS.publicUrl || ''
export const APP_NAME = CONFIGS.appName || 'PayToMe'

export const GEO_LOCATION = {
  '@type': 'GeoCoordinates',
  latitude: 37.4213129,
  longitude: -122.1415651
}

export const ADDRESSES = [
  {
    '@type': 'PostalAddress',
    streetAddress: '3000 El Camino Real STE 4-200',
    addressLocality: 'Palo Alto',
    addressRegion: 'CA',
    postalCode: '94306',
    addressCountry: 'US'
  }
]

// const SERVED_AREAS = ['US']

export const CONTACT_POINTS = [
  {
    '@type': 'ContactPoint',
    telephone: '+1-(650)-963-4969',
    contactOption: 'http://schema.org/TollFree',
    areaServed: 'Worldwide',
    contactType: ['customer service', 'customer support'],
    availableLanguage: {
      '@type': 'Language',
      name: 'English'
    }
  }
]
