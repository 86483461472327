import Head from 'next/head'
import { useRouter } from 'next/router'
import { generateStructuredData } from '@/v3/helpers/structured-data-generator'
import { FaqQuestionType } from '@/v3/types'
import { OGImageType, OpenGraphMetadataType } from '@/v3/types/meta'
import CONFIGS from '@/configs'
import { Fragment } from 'react'

type additionalDataType = {
  faq?: FaqQuestionType[]
}

interface MetaProps {
  title?: string
  keywords: string
  description: string
  searchImages?: OGImageType[]
  favIcon?: string
  noIndex?: boolean
  noFollow?: boolean
  openGraph?: OpenGraphMetadataType
  structuredData?: unknown
  additionalData?: additionalDataType
}

const BASE_URL = CONFIGS.appUrl || ''

/**
 * Must Call this component in the top of every page or component.
 * Pass title, keywords, description, link whatever you want to change.
 * If you call this component and don't pass any value as props, it will show the defaults.
 * For Sequence Read Me: guidelines/META.md
 */

const Meta: React.FC<Readonly<MetaProps>> = ({
  keywords = '',
  description = '',
  title = CONFIGS.appName,
  openGraph,
  structuredData,
  additionalData,
  searchImages = [{ url: '/paytome-social-media.jpg' }],
  favIcon = '/assets/fav/favicon.ico',
  noIndex,
  noFollow
}) => {
  const titleData = title ?? ``
  const descriptionData = description ?? ``
  const keywordsData = keywords ?? ''

  const router = useRouter()
  const asPath = router.asPath === '/' ? '' : router.asPath
  const canonicalUrl = `${CONFIGS.appUrl}${asPath}`

  const imageUrls = searchImages.map(image => {
    const url = typeof image.url === 'string' ? CONFIGS.appUrl.concat(image.url) : image.url.toString()

    return url

    // return {
    //       '@type': 'ImageObject',
    //       url,
    //       // secure_url: url,
    //       width: image.width ?? 92,
    //       height: image.height ?? 92
    //       // alt: image.alt ?? `${image.url} search image in Ship and Storage`
    //     }
  })

  // Structured Data for Local Business
  const structuredDataObject =
    structuredData ??
    generateStructuredData({ imageUrls, description: descriptionData, url: canonicalUrl, ...additionalData })

  const ogImages: OGImageType[] = openGraph?.images ?? [
    { url: '/social-thumbnail.jpg', type: 'image/jpg', alt: 'ship and storage (sas) logo' }
  ]

  const isNotIndexed = Boolean(noIndex)
  const isNotFollowed = Boolean(noFollow)

  const robotsContent: string = (isNotIndexed ? 'noindex' : 'index').concat(isNotFollowed ? ', nofollow' : ', follow')

  return (
    <>
      <Head>
        <meta name='title' content={`${titleData}: ${CONFIGS.appName}`} />
        <meta name='description' content={`${descriptionData}`} />
        <meta name='keywords' content={`${keywordsData}`} />
        <meta name='author' content={CONFIGS.appName} />

        {/*FB META Tags*/}

        <meta property='og:title' content={`${titleData} : ${CONFIGS.appName}`} />
        <meta property='og:description' content={descriptionData} />
        <meta property='og:image' content={`${BASE_URL}/paytome-social-media.jpg`} />
        <meta property='og:url' content={BASE_URL} />
        <meta property='og:site_name' content={CONFIGS.appName} />

        {/*Twitter META Tags*/}

        <meta name='twitter:title' content={`${titleData} : ${CONFIGS.appName}`} />
        <meta name='twitter:description' content={descriptionData} />
        <meta name='twitter:image' content={`${CONFIGS.appUrl}/paytome-social-media.jpg`} />
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:image:alt' content={CONFIGS.appName} />
        <meta name='twitter:site' content={`@${CONFIGS.appName}`} />

        <link rel='icon' href={`${favIcon}`} />
        <link rel='canonical' href={canonicalUrl} />
        <link rel='manifest' href='/assets/fav/site.webmanifest' />
        <link rel='manifest' href='/assets/fav/site.webmanifest' />
        <link rel='apple-touch-icon' sizes='180x180' href='/assets/fav/apple-touch-icon.png' />
        <link rel='icon' type='image/png' sizes='32x32' href='/assets/fav/favicon-32x32.png' />
        <link rel='icon' type='image/png' sizes='16x16' href='/assets/fav/favicon-16x16.png' />

        <title>{`${titleData}: ${CONFIGS.appName}`}</title>

        {ogImages.map((image, index) => {
          const url = typeof image.url === 'string' ? BASE_URL.concat(image.url) : image.url.toString()
          // const secure_url = image?.secureUrl
          //   ? typeof image?.secureUrl === 'string'
          //     ? BASE_URL.concat(image?.secureUrl)
          //     : image?.secureUrl.toString()
          //   : url

          return (
            <Fragment key={index}>
              {/* <meta property='og:image:url' content={url} />
              <meta property='og:image:secure_url' content={secure_url} /> */}
              <meta property='og:image:type' content={image?.type ?? 'image/jpg'} />
              <meta property='og:image:width' content={image?.width?.toString() ?? '1200'} />
              <meta property='og:image:height' content={image?.height?.toString() ?? '630'} />
              <meta
                property='og:image:alt'
                content={image?.alt ?? `${canonicalUrl} open graph service preview in Ship and Storage`}
              />
              <meta property='og:image' content={url} />

              {/*Twitter META image Tag*/}

              <meta name='twitter:image' content={url} />
              <meta
                name='twitter:image:alt'
                content={image?.alt ?? `${canonicalUrl} open graph service preview in Ship and Storage`}
              />
            </Fragment>
          )
        })}

        {openGraph?.videos?.map((video, index) => {
          const url = typeof video.url === 'string' ? BASE_URL.concat(video.url) : video.url.toString()
          const secure_url = video.secureUrl
            ? typeof video.secureUrl === 'string'
              ? BASE_URL.concat(video.secureUrl)
              : video.secureUrl.toString()
            : url

          return (
            <Fragment key={index}>
              <meta property='og:video:url' content={url} />
              <meta property='og:video:secure_url' content={secure_url} />
              <meta property='og:video:type' content={video.type ?? 'video/mp4'} />
              {video.width && <meta property='og:video:width' content={video.width.toString()} />}
              {video.height && <meta property='og:video:height' content={video.height.toString()} />}
            </Fragment>
          )
        })}
        {openGraph?.audio?.map((audio, index) => {
          const url = typeof audio.url === 'string' ? BASE_URL.concat(audio.url) : audio.url.toString()
          const secure_url = audio.secureUrl
            ? typeof audio.secureUrl === 'string'
              ? BASE_URL.concat(audio.secureUrl)
              : audio.secureUrl.toString()
            : url

          return (
            <Fragment key={index}>
              <meta property='og:audio:url' content={url} />
              <meta property='og:audio:secure_url' content={secure_url} />
              <meta property='og:audio:type' content={audio.type ?? 'audio/mpeg'} />
            </Fragment>
          )
        })}

        <meta property='og:determiner' content={openGraph?.determiner ?? ''} />
        <meta property='og:locale' content={openGraph?.locale ?? 'en_US'} />
        {openGraph?.alternateLocales?.map((alternateLocale, index) => (
          <meta key={index} property='og:locale:alternate' content={alternateLocale} />
        ))}

        {/*Mobile META Tags*/}
        <meta name='apple-mobile-web-app-capable' content='yes' />
        <meta name='mobile-web-app-capable' content='yes' />
        <meta name='application-name' content='ShipAndStorage' />
        <meta name='apple-mobile-web-app-title' content='ShipAndStorage' />
        <meta name='apple-mobile-web-app-status-bar-style' content='#FF6D0A' />

        {/* Structured Data */}
        <script type='application/ld+json' dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredDataObject) }} />
      </Head>
    </>
  )
}

export default Meta
