import {
  ADDRESSES,
  APP_NAME,
  BASE_URL,
  CONTACT_POINTS,
  GEO_LOCATION,
  SOCIAL_LINKS,
  WORKING_HOURS
} from '@/v3/constants/structured-data'
import { StructuredDataType } from '@/v3/types/meta'

export const generateDefaultStructuredData = (data: StructuredDataType) => {
  const images = data.imageUrls || []
  const structuredData = {
    // '@context': 'http://schema.org',
    '@type': ['Organization', 'FinancialService'],
    name: APP_NAME,
    alternateName: ['Pay To Me', 'ptm', 'PTM'],
    url: data.url,
    description: data.description,
    '@id': BASE_URL,
    image: images.length > 1 ? images : images[0],
    // logo: data.logo,
    logo: {
      '@type': 'ImageObject',
      // '@id': BASE_URL,
      inLanguage: 'en',
      url: BASE_URL.concat('/assets/images/logos/paytome.png'),
      caption: APP_NAME
    },
    // foundingDate: '2010-05-15',
    telephone: '+1-650-963-4969',
    email: 'support@paytome.co',
    priceRange: '$$',
    geo: GEO_LOCATION,
    address: ADDRESSES,
    openingHoursSpecification: WORKING_HOURS,
    contactPoint: CONTACT_POINTS,
    sameAs: SOCIAL_LINKS
  }

  return structuredData
}
